import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif', 'Montserrat', 'Roboto'].join(','),
  },
  palette: {
    // primary: { main: '#006607' },
    // primary: { main: '#0a6cc7' },
    // primary: { main: '#1371dd' },
    primary: { main: '#096dd9' },
    secondary: { main: '#f50057' },
  },
});

export default theme;
